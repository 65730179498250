<template>
  <div>
    <Brand />
    <Redsout />
    <Mode />
    <Cfield />
    <Business />
    <Platform />
  </div>
</template>

<script>
import Brand from "@/components/pagespers/program/netred/Brand.vue";
import Redsout from "@/components/pagespers/program/netred/Redsout.vue";
import Mode from "@/components/pagespers/program/netred/Mode.vue";
import Cfield from "@/components/pagespers/program/netred/Cfield.vue";
import Business from "@/components/pagespers/program/netred/Business.vue";
import Platform from "@/components/pagespers/program/netred/Platform.vue";

export default {
  components: {
    Brand,
    Redsout,
    Mode,
    Cfield,
    Business,
    Platform
  },

  data() {
    return {};
  },
};
</script>

<style scoped lang="less"></style>
