<template>
  <div class="mode">
    <div class="mode_box">
      <div class="mode_top">
        <p>孵化模式</p>
        <p>INCUBATION</p>
        <p>专业领域，团队打造个性孵化</p>
      </div>
      <div class="content">
        <div class="content_inedx" v-for="(item, index) in content" :key="index">
          <div class="content_lste">
            <img :src="item.img" alt="" />
            <div class="abso">
              <p>{{item.texti}}</p>
              <p>{{item.texta}}</p>
            </div>
          </div>
          <p class="p">{{item.text}}</p>
        </div>
     
   
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/netred/dzfhh.png"),
          texti:"千位网红",
          texta:"网络销售带货",
          text: "网红孵化",
        },
        {
          img: require("@/assets/imgs/netred/dzfhh1.png"),
           texti:"专业团队",
          texta:"一对一定制",
          text: "定制孵化",
        },
        {
          img: require("@/assets/imgs/netred/dzfhh2.png"),
           texti:"为红人及",
          texta:"品牌营销吸粉 ",
          text: "IP孵化",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.mode {
  width: 100%;
  background: #dce5ea;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 100px;
  padding-bottom: 150px;
  .mode_box {
    max-width: 1200px;
    margin: 0 auto;
    .mode_top {
      text-align: center;
      margin-top: 100px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 25px;
      }
      p:nth-child(3) {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #666666;
      }
    }
  }
  .content {
    margin-top: 100px;
    .content_inedx {
      margin: 0 auto;
      width: 375px;
      height: 562px;
      text-align: center;

      .content_lste {
        width: 375px;
        height: 495px;
        overflow: hidden;
        border-radius: 20px;
        position: relative;
        .abso {
          position: absolute;
          left: 0px;
          top: 0px;
          width: 100%;
          height: 30px;
          text-align: center;
          padding-top: 290px;
          p {
            font-size: 23px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #d8d7d6;
            line-height: 34px;
          }
        }
      }
      .p {
        margin-top: 40px;
        font-size: 24px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
      }
    }
    .content_inedx:nth-child(1) {
      float: left;
    }
    .content_inedx:nth-child(2) {
      float: right;
    }
  }
}
</style>

