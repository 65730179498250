<template>
  <div class="redsout">
    <div class="tetx_ilo">
      <i class="i"></i>
      <i class="i"></i>
      <p>
        我们的SAAS平台将帮助每个人进入电商新世代，使我们更接近网络电商新战线。
      </p>
    </div>
    <div class="redsout_box">
      <div class="redsout_top">
        <p>红人助阵</p>
        <p>SUPER KOL</p>
        <p>超6000位全方位提供带货营销驻场支持</p>
      </div>
      <div class="redsout_botton">
        <el-carousel class="content" indicator-position="outside">
          <el-carousel-item v-for="(item, index) in content" :key="index">
            <div class="content_laim">
              <div
                class="content_lst"
                v-for="(items, index) in item.contents"
                :key="index"
              >
                <!--  -->
                <img :src="items.img" alt="" />
                <p>{{ items.text }}</p>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          contents: [
            {
              img: require("@/assets/imgs/netred/hongren1.png"),
              text: "@小野班班",
            },
            {
              img: require("@/assets/imgs/netred/hongren2.png"),
              text: "@珊珊呀",
            },
            {
              img: require("@/assets/imgs/netred/hongren3.png"),
              text: "@半夏",
            },
            {
              img: require("@/assets/imgs/netred/hongren4.png"),
              text: "@一只驼驼",
            },
            {
              img: require("@/assets/imgs/netred/hongren5.png"),
              text: "@粥哈哈",
            },
            {
              img: require("@/assets/imgs/netred/hongren6.png"),
              text: "@果冻Cici-",
            },
            {
              img: require("@/assets/imgs/netred/hongren7.png"),
              text: "@拉拉呀",
            },
            {
              img: require("@/assets/imgs/netred/hongren8.png"),
              text: "@差不多的莎莎",
            },
          ],
        },
        {
          contents: [
            {
              img: require("@/assets/imgs/netred/hongren1.png"),
              text: "@昌仙欧巴",
            },
            {
              img: require("@/assets/imgs/netred/hongren2.png"),
              text: "@艺恩",
            },
            {
              img: require("@/assets/imgs/netred/hongren3.png"),
              text: "@乖乖肉ww",
            },
            {
              img: require("@/assets/imgs/netred/hongren4.png"),
              text: "@陈空空",
            },
            {
              img: require("@/assets/imgs/netred/hongren4.png"),
              text: "@梓萱",
            },
            {
              img: require("@/assets/imgs/netred/hongren5.png"),
              text: "@乔雅baby-",
            },
            {
              img: require("@/assets/imgs/netred/hongren6.png"),
              text: "@芝芝橘",
            },
            {
              img: require("@/assets/imgs/netred/hongren7.png"),
              text: "@琪宝",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.redsout {
  width: 100%;
  overflow: hidden;
  .tetx_ilo {
    margin-top: 80px;
    .i::after {
      content: "";
      display: inline-block;
      width: 158px;
      height: 2px;
      background: #333333;
    }
    .i:nth-child(1) {
      float: left;
    }
    .i:nth-child(2) {
      float: right;
    }
    p {
      text-align: center;
      font-size: 22px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
    }
  }
  .redsout_box {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 80px;
    .redsout_top {
      text-align: center;
      margin-bottom: 70px;
      P:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        margin-bottom: 20px;
        color: #333333;
      }
      P:nth-child(2) {
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #333333;
        margin-bottom: 25px;
      }
      P:nth-child(3) {
        font-size: 18px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #666666;
      }
    }
    .redsout_botton {
      width: 100%;
      .content::-webkit-scrollbar {
        display: none;
      }
      .content_laim {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .content_lst {
          width: 300px;
          height: 280px;
          margin-bottom: 40px;
          text-align: center;
          img {
            width: 250px;
            height: 250px;
            margin-bottom: 10px;
          }
          p {
            color: #000000;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>

