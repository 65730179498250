<template>
  <div class="cfield">
    <div class="cfield_box">
      <div class="cfield_top">
        <p>覆盖领域</p>
        <p>TERRITORY</p>
        <p>红人可转换多个领域开展活动</p>
      </div>
      <div class="cfield_botton">
        <div
          class="content_inedx"
          v-for="(item, index) in content"
          :key="index"
        >
          <img :src="item.img" alt="" />
          <p>{{ item.text }}</p>
          <p>{{ item.texty }}</p>
        </div>
      </div>

      <div class="cfield_botton">
        <div class="content_inedx" v-for="(item, index) in cont" :key="index">
          <img :src="item.img" alt="" />
          <p>{{ item.text }}</p>
          <p>{{ item.texty }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/netred/fgly1.png"),
          text: "美妆",
          texty: "合作美妆品牌超80个",
        },
        {
          img: require("@/assets/imgs/netred/fgly2.png"),
          text: "服装",
          texty: "合作服装品牌超150个",
        },
        {
          img: require("@/assets/imgs/netred/fgly3.png"),
          text: "美食",
          texty: "美食博主引流品牌地位",
        },
        {
          img: require("@/assets/imgs/netred/fgly4.png"),
          text: "母婴",
          texty: "联名品牌超30个",
        },
      ],
      cont: [
        {
          img: require("@/assets/imgs/netred/fgly5.png"),
          text: "农副产品",
          texty: "助力产品带动农副经济",
        },
        {
          img: require("@/assets/imgs/netred/fgly6.png"),
          text: "商演",
          texty: "合作奢侈品牌宣传时装周高定款",
        },
        {
          img: require("@/assets/imgs/netred/fgly7.png"),
          text: "影视",
          texty: "红人客串提高红人价值人设",
        },
        {
          img: require("@/assets/imgs/netred/fgly8.png"),
          text: "更多",
          texty: "更多代言，秀场及杂志同行打造",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.cfield {
  width: 100%;
  overflow: hidden;
  .cfield_box {
    max-width: 1200px;
    margin: 0 auto;
    .cfield_top {
      text-align: center;
      margin-bottom: 100px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-bottom: 25px;
      }
      p:nth-child(3) {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #000000;
      }
    }
    .cfield_botton {
      max-width: 1200px;
      height: 260px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      .content_inedx {
        width: 130px;
        text-align: center;
        p:nth-child(2) {
          font-size: 26px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #5a5a5a;
          margin-top: 6px;
        }
        p:nth-child(3) {
          margin-top: 25px;
          font-size: 18px;
          font-family: Microsoft YaHei;
          line-height: 24px;
          font-weight: 500;
          color: #a5a5a5;
        }
      }
    }
  }
}
</style>

