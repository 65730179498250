<template>
  <div class="business">
    <div class="business_box">
      <div class="business_top">
        <p>代理业务</p>
        <p>PROPAGANDA</p>
        <p>第一领域开展 超级红人代理</p>
      </div>
    <div class="business_button">
   <div class="flat">
            <el-carousel  :interval="4000" type="card"  width:="400px" @change="e=>{active= e}">
        <el-carousel-item v-for="(item, index) in content" :key="index">
          <div class="business_imgs">
            <img :src="item.img" />
            <p class="text">{{ item.text }}</p>
          </div>
        </el-carousel-item>
      </el-carousel>
   </div>
      <div class="content">
        <i></i>
        <i></i>
      </div>
      <div
        class="text_p"
        v-for="(lisa, index) in conte"
        :key="index"
        v-show="active == index"
      >
        <p class="text_ioa">{{ lisa.texti }}</p>
        <p class="text_ima">{{ lisa.textr }}</p>
        <p class="text_ima">
          {{ lisa.texte }}
        </p>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          img: require("@/assets/imgs/netred/shipin.png"),
          text: "主播驻场",
        },
        {
          img: require("@/assets/imgs/netred/shipin.png"),
          text: "直播带货",
        },
        {
          img: require("@/assets/imgs/netred/zhubo.png"),
          text: "活动代言",
        },
      ],
      conte: [
        {
          texti: "团队+主播",
          textr: "邀请红人驻场为直播引流量",
          texte:
            "授品牌方的合作，公司安排相应红人和专属的带货 主播为品牌商进行代理宣传",
        },
        {
          texti: "红人带货",
          textr: "红人拍摄好物种草视频，并在主流平台分享",
          texte: "网红凭借自身粉丝数量和人气，在平台直播带 货以及发布种草视频",
        },
        {
          texti: "活动代言拍摄",
          textr: "海内外人气网红及名模进行商业代言",
          texte:
            "广告代言与投放是品牌第一选，商业广告将在 各大城市和主流平台投放",
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.business {
  width: 100%;
  overflow: hidden;
  margin-top: 80px;
  .business_box {
    max-width: 1200px;
    margin: 0 auto;
    .business_top {
      text-align: center;
      margin-bottom: 50px;
      p:nth-child(1) {
        font-size: 30px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;
      }
      p:nth-child(2) {
        font-size: 25px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
        margin-bottom: 25px;
      }
      p:nth-child(3) {
        font-size: 19px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #000000;
      }
    }
    .business_button {
      max-width: 1200px;
      margin: 0 auto;
      height: 500px;
      .flat {
        float: left;
        margin-top: 80px;
        .business_imgs {
          text-align: center;
          img {
            border-radius: 45px;
            width: 250px;
            height: 250px;
          }
          .text {
            margin-top: 20px;
            font-size: 18px;
            font-weight: bold;
            color: #5c5c5c;
          }
        }
      }

      .content {
        display: flex;
        flex-direction: column;
        i {
          width: 7px;
          height: 7px;
          margin-bottom: 41px;
          background: #000000;
          position: relative;
          left: 42px;
          top: 172px;
          border-radius: 50%;
        }
      }

      .text_p {
        height: 400px;
        float: right;
        .text_ioa {
          width: 300px;
          letter-spacing: 3px;
          font-size: 25px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 35px;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .text_ima {
          width: 420px;
          font-size: 20px;
          font-weight: 400;
          line-height: 45px;
          color: #333333;
        }
      }
    }
  }
}
</style>

